<template>
<span>
    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Funcionários</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click.prevent="atualizaDados">
                <v-icon dark class="mdi mdi-sync"></v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'employee.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Novo Funcionário
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadEmployees" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.empl_foto`]="{ item }">
                <td width="15%" v-if="item.empl_foto"><img :src="`${base_url}upload/employee/${item.empl_foto}?${uuid}`" class="avatar"></td>
                <td width="15%" v-if="!item.empl_foto"><img :src="`${base_url}upload/avatar.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.empl_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.empl_status" @change="onChangeEventHandler(item.empl_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editEmployee(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteEmployee(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailEmployee(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>
            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    uuid
} from "vue-uuid";
import {
    URL_BASE
} from "../../../config/configs";

export default {
    created() {
        this.$store.dispatch("loadEmployees");
    },
    computed: {
        loadEmployees() {
            if (this.$store.state.employees.items.data == null) return [];

            return this.$store.state.employees.items.data;
        }
    },
    name: "EmployeeComponent",
    data() {
        return {
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [{
                    text: "logo",
                    align: "left",
                    sortable: false,
                    value: "empl_foto"
                },
                {
                    sortable: false,
                    text: "Nome",
                    value: "empl_nome"
                },
                {
                    sortable: false,
                    text: "Departamento",
                    value: "departamento.dept_nome"
                },
                {
                    sortable: false,
                    text: "Email",
                    value: "email"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "empl_status"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            employee: [{
                empl_id: "1",
                department: {},
                empl_login: "",
                empl_email: "",
                empl_nome: "",
                empl_datanasc: "",
                empl_foto: "",
                empl_prefixo: "",
                empl_fone: "",
                empl_celular: "",
                empl_nivel: "",
                empl_status: false
            }],
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        atualizaDados() {
            this.$store.dispatch("loadEmployees");
        },
        editEmployee(item) {
            this.$router.push({
                name: "employee.edit",
                params: {
                    empl_id: item.empl_id
                }
            });
        },
        detailEmployee(item) {
            this.$router.push({
                name: "employee.detail",
                params: {
                    empl_id: item.empl_id
                }
            });
        },
        deleteEmployee(item, index) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyEmployee", item.empl_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: "Sucesso",
                                    text: "Dados excluídos com sucesso",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.$store.state.employees.items.data.splice(index, 1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Erro ao excluir dados",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: "Algo errado aconteceu!",
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },

        onChangeEventHandler(empl_id, value) {
            this.$store
                .dispatch("updateStatusEmployee", {
                    empl_id: empl_id,
                    empl_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status do Popup atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status do Popup",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
